Bu sayfadaki puf noktalar: 1 - bu sayfa SepetOdemePage > 4cu asamada odeme tip 3
ise sepet ozeti alanindaki buton yerine gosterilecek
<template>
  <div>
    <!-- @submit.prevent="fetchGaranti3dModel()" -->
    <form
      target="myIframe"
      ref="form"
      action="https://diptengelsin.com/qnb_native.php"
      method="post"
    >
      <input type="hidden" name="cv" :value="this.cvv" />
      <input type="hidden" name="exp" :value="this.kk_date" />
      <input type="hidden" name="kk" :value="this.kartNo" />
      <input type="hidden" name="tutar" :value="this.tutar" />
      <input type="hidden" name="siparisNo" :value="sipNo" />
      <input type="hidden" name="domain" value="https://diptengelsin.com" />

      <!-- <button type="submit">kredi karti bilgilerini gonder</button> -->
    </form>
    <div class="modal" v-show="garantiFormGonder">
      <div class="modal-overlay"></div>
      <div class="modal-container">
        <iFrame id="myIframe" src="" name="myIframe" ref="iframeRef"> </iFrame>
        <div class="modal-close">
          <span @click="closeModal">Kapat</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "kartNo",
    "validMonth",
    "validYear",
    "cvv",
    "garantiFormGonder",
    "sipNo",
    "tutar",
  ],
  emits: ["reset"],

  data() {
    return {
      strMode: "TEST",
      is_sonuc_received: false,
      is_closing: false, //timeoutu sonlandirmak icin sayfa kapanirken devreye girecek bayrak
    };
  },
  computed: {
    kk_date: function() {
      if (this.validMonth != "" && this.validYear != "") {
        return this.validMonth + this.validYear;
      } else return "";
    },
  },
  methods: {
    closeModal() {
      this.is_closing = true;
      //this.garantiFormGonder = false; // paretta resetleyince gerek kalmadi zaten prop change uyarisi veriyordu
      this.$emit("reset");
    },
    submit() {
      this.checkIframeLoaded();
      // formu disaridan tanimli bir butonla tetikleyip calistirilir
      this.$refs.form.submit();
    },
    kartBilgileriniGonder() {},

    checkIframeLoaded() {
      // Get a handle to the iframe element
      var iframe = this.$refs.iframeRef; //document.getElementById('myIframe');
      console.log("iframe=" + iframe);
      console.log("is_sonuc_received=" + this.is_sonuc_received);
      if (iframe != null && this.is_sonuc_received) {
        console.log("href=" + iframe.src);

        try {
          var iframeDoc =
            iframe.contentDocument || iframe.contentWindow.document;

          console.log("href=" + iframeDoc.location.href);

          if (
            iframeDoc.location.href ==
            "https://diptengelsin.com/qnb_3dsonuc.php"
          ) {
            // Check if loading is complete
            if (iframeDoc.readyState == "complete") {
              //iframe.contentWindow.alert("Hello");
              iframe.contentWindow.onload = function() {
                //  alert("I am loaded");
              };
              // The loading is complete, call the function we want executed once the iframe is loaded
              this.afterLoading(iframeDoc);
              return;
            }
          }
        } catch {
          //buraya girebilir vpos.qnbxxxx adresine gittriginde cross origin hatası veriyor cunku
        }
      }

      // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
      let timer = window.setTimeout(this.checkIframeLoaded, 100);
      if (this.is_closing) {
        window.clearTimeout(timer);
      }
    },

    afterLoading() {
      var sonuc = this.getFrameContents();
      console.log(sonuc);
      try {
        var frameResult = JSON.parse(sonuc);

        if (frameResult["status"] == "error") {
          //   garantiFormGonder = false;
          alert("Ödeme Aşamasında Bir Hata Oluştu!");
          this.closeModal();
          this.is_sonuc_received = false;
        } else if (frameResult["status"] == "success") {
          this.$parent.siparisBasarili();
        }
      } catch (e) {
        console.log(e);
        //  garantiFormGonder = false;
        alert("Ödeme Aşamasında Bir Hata Oluştu!");
      }

      //alert("I am here");
    },

    getFrameContents() {
      var iFrame = this.$refs.iframeRef; //  document.getElementById(frameName);
      var iFrameBody;
      if (iFrame.contentDocument) {
        // FF
        iFrameBody = iFrame.contentDocument.getElementsByTagName("body")[0];
      } else if (iFrame.contentWindow) {
        // IE
        iFrameBody = iFrame.contentWindow.document.getElementsByTagName(
          "body"
        )[0];
      }
      return iFrameBody.innerHTML;
    },
  },
  created() {
    // create asamasinda degerlerin ici doldurulur. post icin sorun cikarmasin diye boyle yapildi.
    // eskiden submit() fonksiyonu icinde yapiliyordu ve sistem hatasi veriyordu.
    // form ilk defa tetiklendiginde kartBilgileriniGonder() icindeki degerler dogru sekilde ve zamaninda doldurulmuyor sanirim
    // ama ikincide hic bir sorun cikarmiyordu. o yuzden kartBilgileriniGonder() created aninda cagriliyor
    // ve degerler created aninda doldurulup hazirda bekletiliyor
    this.kartBilgileriniGonder();
    console.log("sipno=" + this.sipNo);
  },

  watch: {
    garantiFormGonder(val) {
      // sepet odeme sayfasinda odeme tip 3 ise bu deger true yapilir
      // data degistiginde bir seyler yapilir
      if (val == true) {
        // console.log("degisti")
      }
    },
  },
  beforeUnmount() {
    this.is_closing = true;
    console.log("unmount oluyor :>>");
  },

  mounted() {
    let _this = this;

    window.addEventListener(
      "message",
      function(event) {
        if (event.origin !== "https://diptengelsin.com") return;
        //3dsonuc.php sayfasında script taglarında 3dsonuc degeri message olarka iframe tarafından post ediliyor.
        //bu mesajı aldıgımızda is_sonuc_received degerini true yaparak 200ms'de bir çalışan iframe'in load oldugunu anlıyoruz ve
        //https://diptengelsin.com/qnb_3dsonuc.php adresi load olmuşssa ekrana basıla ndegeri json olarak parse edip success mi yoksa error mu
        //olduguna bakarak ilgili sipariş başarılı yada hatalı sayfasına yonlendiriyoruz.
        if (event.data == "3dsonuc") {
          console.log("yes");
          _this.is_sonuc_received = true;
        }
        // Parse message back to json
        //var messageObject = JSON.parse(event.data);
        // Get event triggering iFrame
      },
      false
    );

    this.submit(); // form tetiklenir ve sorgu yapilir
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none; /* burada overlay'a pointer-events: none ekleyin */
}

.modal-container {
  position: relative;
  z-index: 1;
  width: 550px;
  height: 550px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #000;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-close:hover {
  background-color: #000;
  color: #fff;
}
</style>
